import styles from '../styles/TrainingInformation.module.scss';

export function Controls({ renaming, setRenaming, handleSave, handleCancel }) {
  if (renaming) {
    return (
      <div className={styles.saveCancelContainer}>
        <div className={styles.save} onClick={handleSave}>
          Save
        </div>
        <div className={styles.cancel} onClick={handleCancel}>
          Cancel
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.documentRename} onClick={() => setRenaming(true)}>
        Rename file
      </div>
    );
  }
}
