import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import { Icon, MediaCarousel } from '../../components';
import styles from '../../styles/home/Notifications.module.scss';
import { isEventFeedbackAvailable } from './NotificationsUtils';

// UTILS

function getDate(date, formatting) {
  try {
    return moment(date.toDate()).format(
      formatting || 'dddd, D MMMM YYYY [at] h:mm A',
    );
  } catch {
    return '';
  }
}

// COMPONENTS

function NewTag() {
  // I don't think this works
  return (
    <div style={{ opacity: 0 }} className={`${styles.newtag} ${styles.shadow}`}>
      NEW
    </div>
  );
}

function GlobalCard({ globalData, showNotifications }) {
  function handleClick() {
    showNotifications(globalData);
  }

  return (
    <div
      className={`${styles.generalContainer} ${styles.shadow}`}
      onClick={handleClick}
    >
      <div>
        <div>General Announcements</div>
        <div className={styles.location}>
          last message:
          {` ${getDate(globalData.latestDate, 'DD/MM/YYYY 	• h:mm A')}`}
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <NewTag />
        <RightOutlined />
      </div>
    </div>
  );
}

function EventCard({ item, showNotifications }) {
  function handleClick() {
    showNotifications(item);
  }

  return (
    <div
      className={`${styles.eventContainer} ${styles.shadow}`}
      onClick={handleClick}
    >
      <div className={styles.newtagcont}>
        <NewTag />
      </div>
      <div className={styles.upSubContainer}>
        <div className={styles.subContainer}>
          <Icon name="icon-football" size={24} className={styles.icon} />
          <div>
            <div className={styles.eventTitle}>{item.eventData?.eventName}</div>
            <div className={styles.location}>
              {`at ${item.eventData?.eventLocation.place}${
                item.eventData?.eventLocation.city
                  ? `, ${item.eventData?.eventLocation.country}`
                  : ''
              }`}
            </div>
            <div className={styles.time}>
              last message: {getDate(item.latestDate, 'DD/MM/YYYY 	• h:mm A')}
            </div>
          </div>
        </div>
        <RightOutlined />
      </div>
    </div>
  );
}

function SelectedNotifications({
  selectedData,
  hideNotifications,
  showEventFeedback,
}) {
  return (
    <div className={styles.notiDetailContainer}>
      <div className={styles.topContainer}>
        <LeftOutlined className={styles.backIcon} onClick={hideNotifications} />
        <div>
          <div className={styles.time}>
            {getDate(selectedData?.eventData?.eventDate)}
          </div>
          <div className={styles.title}>
            {selectedData?.eventData?.eventName}
          </div>
        </div>
      </div>
      <div style={{ padding: '0 20px 50px 20px' }}>
        {selectedData?.content?.map((item) => (
          <NotificationField
            key={item.id}
            item={item}
            selectedData={selectedData}
          />
        ))}
        {isEventFeedbackAvailable(selectedData.eventData) && (
          <div className={`${styles.maincard} ${styles.shadow}`}>
            <div className={`${styles.newtagcont}`}>
              <NewTag />
            </div>
            <ImageLogo selectedData={selectedData} />
            <div className={styles.titleTop}>
              <div className={styles.eventTitle}>Event Feedback</div>
              <div className={styles.location}>Automated Message</div>
            </div>
            <div className={styles.time}>
              {getDate(selectedData?.eventData?.postEventDate)}
              <br />
            </div>
            <div className={styles.paragraph}>
              {selectedData?.eventData?.postEventMsg}
            </div>
            <Button
              type="link"
              className={styles.feedbackButton}
              onClick={showEventFeedback}
            >
              Leave feedback
              <RightOutlined />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function ImageLogo({ item, selectedData }) {
  // For global announcements display small company logo
  let authorPictureURL =
    item?.type === 'global' && item?.companyLogoUrl
      ? item.companyLogoUrl
      : selectedData?.eventData?.eventImage ||
        '/images/announcements-placeholder.png';

  return (
    <div className={`${styles.imagecont} ${styles.shadow}`}>
      <img src={authorPictureURL} />
    </div>
  );
}

function NotificationField({ item, selectedData }) {
  return (
    <div className={`${styles.maincard} ${styles.shadow}`}>
      <div className={`${styles.newtagcont}`}>
        <NewTag />
      </div>
      <ImageLogo item={item} selectedData={selectedData} />
      <div className={styles.titleTop}>
        <div className={styles.eventTitle}>{item?.title}</div>
        <div className={styles.location}>
          {selectedData?.eventData?.eventLocation ? (
            `${selectedData?.eventData?.eventLocation.place}${
              selectedData?.eventData?.eventLocation.city
                ? `, ${selectedData?.eventData?.eventLocation.city}`
                : ''
            }`
          ) : (
            <div style={{ height: 20 }} />
          )}
        </div>
      </div>
      {item?.media?.length > 0 && (
        <div
          className={styles.mediaCarouselContainer}
          style={{
            marginRight: -20,
            marginLeft: -20,
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <MediaCarousel data={item} />
        </div>
      )}
      <div className={styles.time}>{getDate(item.dateCreated)}</div>
      <div className={styles.paragraph}>{item?.content}</div>
    </div>
  );
}

export { EventCard, GlobalCard, NewTag, SelectedNotifications };
