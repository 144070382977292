import { QrcodeOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import Link from 'next/link';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { FirebaseContext, UserContext } from '../../../contexts';
import { Icon, Loading } from '../../components';
import styles from '../../styles/home/Events.module.scss';
import EventOverview from '../event-overview/EventOverview';
import { isModuleInCompanyPackage } from '../../../utils';
import { Logo } from './Logo';
import { Arrow } from './Arrow';
import {
  COMPANY_PACKAGES,
  MODULES,
} from '../../../constants/companyPackagesConstants';
import dayjs from 'dayjs';
import {
  getEventParticipantFirestore,
  ScannerPage,
} from '../entry-points/ScannerPage';

// ----- utils ------
const getDate = (date) => {
  return moment(date.toDate()).format('dddd, D MMMM YYYY [at] h:mm A');
};

const Events = (props) => {
  const firebase = useContext(FirebaseContext);
  const { user } = useContext(UserContext);
  const db = firebase.firestore();
  const router = useRouter();
  const [showEvent, setShowEvent] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState();
  const [defaultEventTab, setDefaultEventTab] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [toast, setToast] = useState(false);
  const [isavailable, setIsavailable] = useState(false);
  const [unavailable, setUnavailable] = useState(false);
  const [suspended, setSuspended] = useState(false);
  const [suspendedFromCompany, setSuspendedFromCompany] = useState(false);
  const [eventResponses, setEventRespponses] = useState([]);
  const [respondedEvent, setRespondedEvent] = useState('');
  const [unsetListener, setUnsetListener] = useState();

  useEffect(() => {
    // initiateFetch();
    getEventResponses();
  }, []);

  useEffect(() => {
    initiateFetch();
    return () => {
      if (unsetListener) unsetListener();
    };
  }, [isavailable, respondedEvent, toast]);

  const initiateFetch = async () => {
    if (unsetListener) unsetListener();
    let unsetter = fetchData();
  };

  const fetchData = () => {
    setIsLoading(true);
    const eventsData = [];
    const today = new Date();
    today.setHours(0, 0, 0);
    // const yesterday = new Date(today);
    // yesterday.setDate(yesterday.getDate() - 1);

    let data = [];
    const onResult = (QuerySnapshot) => {
      data = [];
      QuerySnapshot.docs.forEach(async (namecardDoc) => {
        data.push(namecardDoc.data());
      });
      setData(data.filter((e) => !e.suspendedStaff?.includes(user.id)));
    };

    const onError = (error) => {
      console.error(error);
    };

    const query = db
      .collection('events')
      .where('recruitedStaff', 'array-contains', user.id)
      .where('status', '==', 'published')
      .where('eventDate', '>', today)
      // .where("suspendedStaff", 'not-in', [[user.id]])
      .orderBy('eventDate');

    setIsLoading(false);
    return query.onSnapshot(onResult, onError);
  };

  useEffect(() => {
    if (toast) {
      const interval = setInterval(() => {
        setToast(false);
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [toast]);

  useEffect(() => {
    if (isavailable) {
      const interval = setInterval(() => {
        setIsavailable(false);
        setRespondedEvent('');
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [isavailable]);

  useEffect(() => {
    if (unavailable) {
      const interval = setInterval(() => {
        setUnavailable(false);
        setRespondedEvent('');
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [unavailable]);

  useEffect(() => {
    if (suspended) {
      const interval = setInterval(() => {
        setSuspended(false);
      }, 8000);
      return () => clearInterval(interval);
    }
  }, [suspended]);

  useEffect(async () => {
    // Prefetch data for PWA offline caching
    if (data.length > 0) {
      for (const event of data) {
        let testSnapshot = await db
          .collection('briefings')
          .where('eventId', '==', event?.eventId)
          .get();
        // console.log('fetchedData:', testSnapshot.docs[0].data());
      }
    }
  }, [data]);

  const determineIcon = (item) => {
    if (item?.available?.includes(user.id)) {
      return (
        <Icon
          name="icon-three-o-clock-clock"
          size={24}
          className={styles.waitingIcon}
          style={{ color: '#FAAD14', backgroundColor: '#ffffff' }}
        />
      );
    } else if (item?.acceptedStaff?.includes(user.id)) {
      return (
        <Icon
          name="icon-tick"
          size={12}
          className={styles.acceptedIcon}
          style={{ color: '#52C41A', backgroundColor: '#ffffff' }}
        />
      );
    } else if (item?.rejectedStaff?.includes(user.id)) {
      return (
        <Icon
          name="icon-cancel"
          size={12}
          className={styles.rejectedIcon}
          style={{ color: '#FB4953', backgroundColor: '#ffffff' }}
        />
      );
    } else if (item?.unAvailable?.includes(user.id)) {
      return (
        <Icon
          name="icon-cancel"
          size={12}
          className={styles.rejectedIcon}
          style={{ color: '#FB4953', backgroundColor: '#ffffff' }}
        />
      );
    } else {
      return (
        <Icon
          name="icon-EventRSVP"
          size={12}
          className={styles.acceptedIcon}
          style={{ color: '#FCEA25', backgroundColor: '#ffffff' }}
        />
      );
    }
  };

  const determineIconBackground = (item) => {
    if (item?.available?.includes(user.id)) {
      return '#FAAD14';
    } else if (item?.acceptedStaff?.includes(user.id)) {
      return '#52C41A';
    } else if (item?.rejectedStaff?.includes(user.id)) {
      return '#FB4953';
    } else if (item?.unAvailable?.includes(user.id)) {
      return '#FB4953';
    } else {
      return '#FCEA25';
    }
  };

  const renderPlaceholer = () => {
    return (
      <>
        <div className={styles.topLayOut}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.placeholderText}>Welcome to Aposto!</div>
        </div>

        <div style={{ padding: '30px' }}>
          <div className={styles.homeScreen}>
            <div className={styles.placeholderSubText}>
              <b>Your events</b>
              <p className={styles.paragraph}>
                You'll receive briefings and other important information here.
                Additionally, you'll get event notifications via SMS for the
                events you're involved in.
              </p>
            </div>
          </div>

          <div className={styles.info}>
            <p className={styles.infoTitle}>
              While waiting for your next event...
            </p>
            <p className={styles.paragraph}>
              Here are a few things you can do.{' '}
            </p>

            <a href="/app/profile" className={styles.infoTile}>
              <div>
                <b style={{ color: '#000' }}>Add your photo</b>
                <p className={styles.paragraph}>Click here to add your photo</p>
              </div>
              <div>
                <Arrow />
              </div>
            </a>

            <a href="app/save-aposto" className={styles.infoTile}>
              <div>
                <b style={{ color: '#000' }}>Save Aposto to your phone</b>
                <p className={styles.paragraph}>
                  View tutorial on how to save the app
                </p>
              </div>
              <div>
                <Arrow />
              </div>
            </a>

            <div
              className={styles.infoTile}
              onClick={() => props.handleTabChange('training')}
            >
              <div>
                <b style={{ color: '#000' }}>Go to training centre</b>
                <p className={styles.paragraph}>
                  View training information & modules
                </p>
              </div>
              <div>
                <Arrow />
              </div>
            </div>

            <Link
              href={{
                pathname: '/app/profile',
                query: { openLanguageSelection: true },
              }}
            >
              <a className={styles.infoTile}>
                <div>
                  <b style={{ color: '#000' }}>Select spoken language(s)</b>
                  <p className={styles.paragraph}>
                    Choose from a list of languages
                  </p>
                </div>
                <div>
                  <Arrow />
                </div>
              </a>
            </Link>

            <Link href="/app/profile">
              <a href="#" className={styles.infoTile}>
                <div>
                  <b style={{ color: '#000' }}>Change font</b>
                  <p className={styles.paragraph}>
                    Dyslexie font option available
                  </p>
                </div>
                <div>
                  <Arrow />
                </div>
              </a>
            </Link>
          </div>
        </div>
      </>
    );
  };

  const renderSuspendedPlaceholer = () => {
    return (
      <div>
        <div className={styles.placeholderText}>Welcome to Aposto!</div>
        <div className={styles.placeholderSubText}>
          You are suspended from this company. Please contact your manager for
          more information.
        </div>
      </div>
    );
  };

  const getEventResponses = async (responseType = 'accepted') => {
    try {
      let eventsSnapshot;
      if (responseType === 'accepted') {
        eventsSnapshot = await db
          .collection('events')
          .where('available', 'array-contains', user.id)
          .get();
      } else {
        eventsSnapshot = await db
          .collection('events')
          .where('available', 'not-in', [[user.id]])
          .get();
      }

      let _event = [];
      eventsSnapshot.forEach((e) => {
        _event.push(e.data());
      });

      _event = _event.filter((e) => !e.suspendedStaff?.includes(user.id));

      setEventRespponses(_event);
    } catch (e) {
      console.error(e);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  if (user?.suspendedFromCompany) {
    return renderSuspendedPlaceholer();
  }
  // backgroundColor:"#000"
  return (
    <div
      className={styles.container}
      style={{
        backgroundColor:
          data?.length === 0 && renderPlaceholer() ? '#fff' : 'initial',
      }}
    >
      {data?.length === 0 && renderPlaceholer()}
      <div className={styles.topspace}>
        <div className={styles.title} style={{ color: '#fff' }}>
          Next Event
        </div>
      </div>
      {toast && (
        <div className={styles.toast}>
          Thank you for signing up for this event! Please wait for the approval.
        </div>
      )}

      {isavailable && (
        <div className={styles.toast}>
          Thanks for telling us you are available for the {respondedEvent}
          event. Please wait for confirmation.
        </div>
      )}

      {unavailable && (
        <div className={styles.toast}>
          Thanks for telling us that you are not available for the
          {respondedEvent} event.
        </div>
      )}

      {suspended && (
        <div className={styles.toast}>
          You are Blocked from this event. Please contact your manager for more
          information.
        </div>
      )}
      {data?.[0] && (
        <NextEventCard
          event={data[0]}
          setSelectedEventData={setSelectedEventData}
          setShowEvent={setShowEvent}
          setDefaultEventTab={setDefaultEventTab}
        />
      )}
      <div style={{ padding: '30px' }}>
        <div className={styles.infoEvent}>
          <a
            href="#"
            className={styles.pastEvent}
            onClick={() => router.push('app/past-events')}
          >
            <div>
              <b style={{ color: '#000' }}>Past events</b>
            </div>
            <div>
              <Arrow />
            </div>
          </a>
        </div>
      </div>
      {/* slice to not show the first event as it is rendered with image */}
      {data?.slice(1).map((item) => (
        <EventCard
          key={item.eventId}
          item={item}
          setDefaultEventTab={setDefaultEventTab}
          setSelectedEventData={setSelectedEventData}
          setShowEvent={setShowEvent}
          getDate={getDate}
          determineIconBackground={determineIconBackground}
          determineIcon={determineIcon}
        />
      ))}
      <Drawer
        placement="bottom"
        closable={false}
        onClose={() => setShowEvent(false)}
        visible={showEvent}
        height="100%"
        destroyOnClose
        className={styles.eventOverviewDrawer}
      >
        <EventOverview
          onClose={() => setShowEvent(false)}
          defaultEventTab={defaultEventTab}
          data={selectedEventData}
          setToast={setToast}
          setRespondedEvent={setRespondedEvent}
          setIsavailable={(a) => setIsavailable(a)}
          setUnavailable={setUnavailable}
          suspended={suspended}
          setSuspended={setSuspended}
          setSuspendedFromCompany={setSuspendedFromCompany}
          suspendedFromCompany={suspendedFromCompany}
        />
      </Drawer>
    </div>
  );
};

function EventCard({
  item,
  setDefaultEventTab,
  setSelectedEventData,
  setShowEvent,
  determineIconBackground,
  determineIcon,
}) {
  const { user, companiesData } = useContext(UserContext);

  const isPackageBriefingTraining =
    companiesData[item.companyId].package === COMPANY_PACKAGES.briefingTraining;
  return (
    <div
      className={`${styles.eventContainer} ${styles.shadow}`}
      onClick={() => {
        setDefaultEventTab('briefing');
        setSelectedEventData(item);
        setShowEvent(true);
      }}
    >
      <div className={styles.header}>
        <div className={styles.time}>{getDate(item.eventDate)}</div>
        {!isPackageBriefingTraining && (
          <div
            className={styles.clockContainer}
            style={{
              color: determineIconBackground(item),
              backgroundColor: determineIconBackground(item),
            }}
          >
            {determineIcon(item)}
          </div>
        )}
      </div>

      <div className={styles.subContainer}>
        <Icon name="icon-football" size={24} className={styles.icon} />
        <div>
          <div className={styles.eventTitle}>{item.eventName}</div>
          <div className={styles.location}>{`at ${item.eventLocation.place}${
            item.eventLocation.city ? `, ${item.eventLocation.country}` : ''
          }`}</div>
        </div>
      </div>
    </div>
  );
}

function NextEventCard({
  event,
  setSelectedEventData,
  setShowEvent,
  setDefaultEventTab,
}) {
  const { user, companiesData } = useContext(UserContext);

  const comingsoonIcon = (item) => {
    if (item?.available?.includes(user.id)) {
      return (
        <Icon
          name="icon-three-o-clock-clock"
          size={20}
          className={styles.waitingIcon}
          style={{
            color: '#FAAD14',
            backgroundColor: '#ffffff',
            padding: '5px',
            borderRadius: '3rem',
          }}
        />
      );
    } else if (item?.acceptedStaff?.includes(user.id)) {
      return (
        <Icon
          name="icon-tick"
          size={20}
          className={styles.acceptedIcon}
          style={{
            color: '#52C41A',
            backgroundColor: '#ffffff',
            padding: '5px',
            borderRadius: '3rem',
          }}
        />
      );
    } else if (item?.rejectedStaff?.includes(user.id)) {
      return (
        <Icon
          name="icon-cancel"
          size={20}
          className={styles.rejectedIcon}
          style={{
            color: '#FB4953',
            backgroundColor: '#ffffff',
            padding: '5px',
            borderRadius: '3rem',
          }}
        />
      );
    } else if (item?.unAvailable?.includes(user.id)) {
      return (
        <Icon
          name="icon-cancel"
          size={20}
          className={styles.rejectedIcon}
          style={{
            color: '#FB4953',
            backgroundColor: '#ffffff',
            padding: '5px',
            borderRadius: '3rem',
          }}
        />
      );
    } else {
      return <div style={{ color: 'black' }}>RSVP</div>;
    }
  };

  const comingsoonIconBackground = (item) => {
    if (item?.available?.includes(user.id)) {
      return '#FAAD14';
    } else if (item?.acceptedStaff?.includes(user.id)) {
      return '#52C41A';
    } else if (item?.rejectedStaff?.includes(user.id)) {
      return '#FB4953';
    } else if (item?.unAvailable?.includes(user.id)) {
      return '#FB4953';
    } else {
      return '#FCEA25';
    }
  };

  function isScheduleVisible() {
    const companyPackageForEvent = companiesData[event.companyId].package;
    return isModuleInCompanyPackage(companyPackageForEvent, MODULES.scheduling);
  }

  const eventImageSource =
    event?.eventImage || './images/event-placeholder.svg';

  const eventImageClass = event?.eventImage
    ? `${styles.eventImage}`
    : `${styles.imagePlaceholder}`;

  return (
    <>
      <CheckHeader nextEvent={event} />
      <div
        style={{
          position: 'relative',
          marginLeft: '30px',
          marginRight: '30px',
          marginTop: '45px',
        }}
        onClick={() => {
          setSelectedEventData(event);
          setShowEvent(true);
        }}
      >
        <div className={`${styles.comingUpContainer} ${styles.shadow}`}>
          <div className={styles.subContainer}>
            <Icon name="icon-football" size={24} className={styles.icon} />
            <div>
              <div className={styles.eventTitle}>{event.eventName}</div>
              <div className={styles.location}>{`at ${
                event.eventLocation.place
              }${
                event.eventLocation.city
                  ? `, ${event.eventLocation.country}`
                  : ''
              }`}</div>
            </div>
          </div>

          <div className={styles.time}>{getDate(event.eventDate)}</div>

          <div
            style={{
              marginTop: '15px',
              marginBottom: '20px',
              textAlign: 'center',
            }}
          >
            <div>
              {isScheduleVisible() && (
                <div
                  className={styles.comingUpButton}
                  style={{
                    backgroundColor: comingsoonIconBackground(event),
                  }}
                >
                  {comingsoonIcon(event)}
                </div>
              )}
              <img
                src={eventImageSource}
                className={eventImageClass}
                style={{ zIndex: 1, marginBottom: '2rem' }}
              />
            </div>
          </div>
        </div>

        <div className={`${styles.comingUpBottomContainer} ${styles.shadow}`}>
          <div
            className={styles.comingUpBottomContainer_container}
            onClick={() => setDefaultEventTab('briefing')}
          >
            <div className={styles.comingContainerTitle}>BRIEFING</div>
            <div className={styles.comingIconContainer}>
              <Icon name="icon-info" size={28} className={styles.comingIcon} />
            </div>
          </div>
          <div
            className={styles.comingUpBottomContainer_container}
            onClick={() => setDefaultEventTab('schedule')}
          >
            <div className={styles.comingContainerTitle}>SCHEDULE</div>
            <div className={styles.comingIconContainer}>
              <Icon
                name="icon-three-o-clock-clock"
                size={28}
                className={styles.comingIcon}
              />
            </div>
          </div>
          <div
            className={styles.comingUpBottomContainer_container}
            onClick={() => setDefaultEventTab('training')}
          >
            <div className={styles.comingContainerTitle}>QUIZ</div>
            <div className={styles.comingIconContainer}>
              <Icon name="icon-qa" size={28} className={styles.comingIcon} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function CheckHeader({ nextEvent }) {
  const router = useRouter();
  const firebase = useContext(FirebaseContext);
  const { user, companiesData } = useContext(UserContext);
  const [eventParticipant, setEventParticipant] = useState(null);

  const nextEventCompany = companiesData[nextEvent?.companyId];

  if (!nextEvent) {
    return null;
  }

  useEffect(() => {
    async function getEventParticipant() {
      const fetchedParticipant = await getEventParticipantFirestore(
        firebase,
        nextEvent.eventId,
        user.id,
      );
      setEventParticipant(fetchedParticipant);
    }
    if (nextEvent?.eventId && user?.id) {
      getEventParticipant();
    }
  }, [nextEvent?.eventId, user?.id]);

  function handleQrCodeClick() {
    router.push(`/app/scanner?eventId=${nextEvent.eventId}`);
  }

  const isEventToday = dayjs(nextEvent?.eventDate.toDate()).isSame(
    dayjs(),
    'day',
  );

  if (
    !isEventToday ||
    eventParticipant?.didCheckOut ||
    nextEventCompany?.isQRCodeDisabled
  ) {
    return (
      <div className={styles.upcoming__text}>
        <span>Coming up next</span>
      </div>
    );
  }

  return (
    <div className={styles.upcoming__text}>
      <div className={styles.checkInContainer} onClick={handleQrCodeClick}>
        <div className={styles.qrCodeIcon}>
          <QrcodeOutlined />
        </div>
        <span>{eventParticipant?.didCheckIn ? 'Check out' : 'Check in'}</span>
      </div>
    </div>
  );
}

export default Events;
