import styles from './TabSelect.module.scss';

function TabSelect({ text, isSelected, onClick }) {
  return (
    <div className={styles.TabSelect} onClick={onClick}>
      {isSelected && (
        <>
          <div className={styles.selected}>{text}</div>
          <div className={styles.yellowLine} />
        </>
      )}
      {!isSelected && <div className={styles.notSelected}>{text}</div>}
    </div>
  );
}

export { TabSelect };
