import { useState, useEffect, useContext } from 'react';
import { UserContext, FirebaseContext } from '../../../../contexts';

export function useNotificationStatus() {
  const [hasNewNotifications, setHasNewNotifications] = useState();
  const [notificationCount, setNotificationCount] = useState(0);
  const firebase = useContext(FirebaseContext);
  const db = firebase.firestore();
  const { user, refreshUser } = useContext(UserContext);

  useEffect(() => {
    if (user?.memberOfCompanyId?.length > 0) {
      const unsubscribeNotifications = db
        .collection('notifications')
        .where('companyId', 'in', user?.memberOfCompanyId)
        .onSnapshot(async (querySnapshot) => {
          // let userSnapshot = await db.collection('users').doc(user.id).get();
          // let userData = userSnapshot.data();

          let previousNotificationsCount =
            user?.notifications?.totalCount > 0
              ? user?.notifications?.totalCount
              : 0;
          if (
            querySnapshot.docs.length > 0 &&
            querySnapshot.docs.length > previousNotificationsCount
          ) {
            setNotificationCount(querySnapshot.docs.length);
            setHasNewNotifications(true);
          } else {
            setNotificationCount(querySnapshot.docs.length);
          }
        });

      return () => {
        unsubscribeNotifications();
      };
    }

    return null;
  });

  const handleHasSeenNotification = async () => {
    setHasNewNotifications(false);
    await db
      .collection('users')
      .doc(user.id)
      .update({
        notifications: {
          ...user?.notifications,
          totalCount: notificationCount,
        },
      });
    refreshUser();
  };

  return [hasNewNotifications, handleHasSeenNotification];
}
