import { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../../../contexts';
import { divideNameAndType } from '../../../../utils';
import styles from '../styles/TrainingInformation.module.scss';
import { Feedback } from './Feedback';
import {
  updateFileNameFirestore,
  readFileFirestore,
} from '../services/trainingInformationFirebase';
import { DocumentField } from './DocumentField';
import { Controls } from './Controls';

export function WorkerDocument({ file, handleUpload }) {
  const firebase = useContext(FirebaseContext);
  const [renaming, setRenaming] = useState(false);
  const [newFileName, setNewFileName] = useState('');
  const [newFileType, setNewFileType] = useState('');

  useEffect(() => {
    const [Fname, Ftype] = divideNameAndType(file.fileName);
    setNewFileName(Fname);
    setNewFileType(Ftype);
  }, []);

  async function handleSave() {
    setRenaming(false);
    const joinedName = `${newFileName}.${newFileType}`;
    await updateFileNameFirestore(firebase, file, joinedName);
  }

  async function handleCancel() {
    setRenaming(false);
    file = await readFileFirestore(firebase, file.id);
    const [Fname, Ftype] = divideNameAndType(file.fileName);
    setNewFileName(Fname);
    setNewFileType(Ftype);
  }

  // only show feedback if file is rejected
  const shouldShowFeedback = file.feedback && file.fileStatus === 'Rejected';

  return (
    <div className={styles.documentContainer}>
      <DocumentField
        renaming={renaming}
        file={file}
        newFileName={newFileName}
        setNewFileName={setNewFileName}
        newFileType={newFileType}
      />
      {shouldShowFeedback && (
        <Feedback feedback={file.feedback} handleUpload={handleUpload} />
      )}
      <Controls
        renaming={renaming}
        setRenaming={setRenaming}
        handleSave={handleSave}
        handleCancel={handleCancel}
      />
    </div>
  );
}
