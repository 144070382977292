import {
  UploadPdfToStorage,
  uploadPhotoToStorage,
} from '../../../../admin/utils/FirebaseStorage';

async function updateFileNameFirestore(firebase, fileToUpdate, newName) {
  const db = firebase.firestore();
  try {
    const docRef = db.collection('userCertificates').doc(fileToUpdate.id);
    await docRef.update({
      fileName: newName,
    });
  } catch (error) {
    console.error('Error updating name', error);
  }
}

async function readFileFirestore(firebase, fileId) {
  const db = firebase.firestore();
  const doc = await db.collection('userCertificates').doc(fileId).get();
  return { ...doc.data(), id: doc.id };
}

async function createFileLinkFirestore(file, downloadURL, newDocRef, worker) {
  try {
    // create a new document to reference a file in cloud storage
    const newDocument = {
      userId: worker.id,
      fileName: file.name.replace(/\s/g, '_'),
      fileType: file.type,
      fileURL: downloadURL,
      fileStatus: 'Pending approval',
      feedback: '',
      id: newDocRef.id,
      uploadByAdmin: false,
      isReviewed: false,
      createdAt: new Date(),
    };
    // save document in cloud
    return await newDocRef.set(newDocument);
  } catch (error) {
    console.error('Error adding document: ', error);
    return error;
  }
}

async function uploadFileFirebase(firebase, newFile, worker) {
  const db = firebase.firestore();
  // check file type
  const isFilePdf = newFile.type === 'application/pdf';
  const isFileImage = newFile.type.includes('image');

  // get id of the new document that will be saved in firestore
  const newDocRef = await db.collection('userCertificates').doc();
  // filePath to save a file in cloud storage using the same id as firestore
  let filePath = `certificates/${worker.id}/${newDocRef.id}`;

  let downloadURL;
  if (isFilePdf) {
    downloadURL = await UploadPdfToStorage(newFile, filePath, firebase);
  } else if (isFileImage) {
    downloadURL = await uploadPhotoToStorage(newFile, filePath, firebase);
  } else {
    // return false if upload was unsuccessful
    return false;
  }
  // if upload was successful
  // create a firestore reference for a newFile in a Cloud storage
  await createFileLinkFirestore(newFile, downloadURL, newDocRef, worker);
  return true;
}

export { updateFileNameFirestore, readFileFirestore, uploadFileFirebase };
