import styles from '../styles/TrainingInformation.module.scss';

export function Feedback({ feedback, handleUpload }) {
  return (
    <div className={styles.documentFeedback}>
      <div className={styles.documentFeedbackText}>{feedback}</div>
      <label>
        <input
          className={styles.hide}
          type="file"
          accept="image/*,.pdf"
          onChange={handleUpload}
          multiple
        />
        <div className={styles.documentFeedbackReupload}>Reupload</div>
      </label>
    </div>
  );
}
