import styles from '../styles/TrainingInformation.module.scss';

export function TrainingLogPreview({ trainingLog }) {
  return (
    <div className={styles.TrainingLogPreview}>
      <div>{trainingLog.text}</div>
      <div className={styles.logDate}>{trainingLog.completionDate}</div>
    </div>
  );
}
