import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import styles from '../styles/TrainingInformation.module.scss';

export function UploadButton({ handleUpload, uploadLoading }) {
  return (
    <label className={styles.upload}>
      <input
        className={styles.hide}
        type="file"
        accept="image/*,.pdf"
        onChange={handleUpload}
        multiple
      />
      {uploadLoading ? (
        <>
          <LoadingOutlined className={styles.uploadIcon} />
          Uploading
        </>
      ) : (
        <>
          <UploadOutlined className={styles.uploadIcon} />
          Upload training documents
        </>
      )}
    </label>
  );
}
