import {
  ExclamationCircleFilled,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Divider, Drawer, Progress } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { SHARED_CONFIG } from '../../../config/sharedConfig';
import { FirebaseContext, UserContext } from '../../../contexts';
import { Loading } from '../../components';
import styles from '../../styles/components/TrainingCentre.module.scss';
import { TrainingInformation } from '../training-information';
import EmptyTopicQuiz from './EmptyTopicQuiz';
import Quiz from './Quiz';
import Topics from './Topics';
import { TrainingContent } from './TrainingContent';

const TrainingCentre = () => {
  const firebase = useContext(FirebaseContext);
  const { user, refreshUser } = useContext(UserContext);
  const db = firebase.firestore();
  const [isLoading, setIsLoading] = useState(true);
  const [assignedModules, setAssignedModules] = useState([]);
  const [completedAssignedModules, setCompletedAssignedModules] = useState([]);
  const [incompletedAssignedModules, setIncompletedAssignedModules] = useState(
    [],
  );
  const [showDrawer, setShowDrawer] = useState(false);
  const [showCompletedDrawer, setShowCompletedDrawer] = useState(false);
  const [showTrainingInformation, setShowTrainingInformation] = useState(false);
  const [topics, setTopics] = useState({ content: 'blabla' });
  const [moduleId, setModuleId] = useState();
  const [showTopic, setShowTopic] = useState();
  const [showQuiz, setShowQuiz] = useState();
  const [drawerColor, setDrawerColor] = useState();
  const [activeTitle, setActiveTitle] = useState();

  useEffect(async () => {
    await fetchAssignedModules();
  }, [user?.memberOfCompanyId]);

  const fetchAssignedModules = async () => {
    // Fetch general module data based on user's company
    if (!user?.memberOfCompanyId?.length) {
      return;
    }

    // Fetch module-role assignments for the user's company
    let modulesSnapshot = await db
      .collection('moduleRoleAssignment')
      .where('companyId', 'in', user?.memberOfCompanyId)
      .get();

    // Create an array of module-role assignments
    const moduleRoleArr = modulesSnapshot.docs.map((doc) => {
      return { ...doc.data() };
    });

    // Get unique general module IDs from module-role assignments
    let validGeneralModuleIds = [
      ...new Set([...moduleRoleArr].map((i) => i.id)),
    ];
    // console.log("🚀 ~ file: TrainingCentre.js:64 ~ fetchAssignedModules ~ validGeneralModuleIds:", validGeneralModuleIds)

    // Fetch assigned modules for the user
    const assignedModulesRef = db.collection('assignedModules');
    const assignedModulesSnapshot = await assignedModulesRef
      .where('userId', '==', user.id)
      .get();

    // Create an array of assigned module data
    let assignedModulesData = assignedModulesSnapshot.docs.map((doc) =>
      doc.data(),
    );
    // console.log('assignedModulesData', assignedModulesData);

    // Filter assigned module data to only include general modules
    let filteredGeneralModules = [...assignedModulesData].filter((i) =>
      validGeneralModuleIds.includes(i.moduleId),
    );

    const promises = [];
    const visitedModuleIds = new Set(); // Track visited module IDs

    // Fetch module and topic data for filtered general modules
    filteredGeneralModules.forEach((item) => {
      if (!visitedModuleIds.has(item.moduleId)) {
        promises.push(fetchModules(item));
        visitedModuleIds.add(item.moduleId);
      }
    });

    const promisesResult = await Promise.all(promises);
    const finalGeneralModules = promisesResult.filter(
      (obj) => Object.keys(obj).length !== 0,
    );

    setAssignedModules(finalGeneralModules);

    // Separate completed and incomplete modules
    setCompletedAssignedModules(
      finalGeneralModules.filter(
        (i) => i.readModule && i.tookQuiz && i.grade >= i.passingGrade,
      ),
    );

    setIncompletedAssignedModules(
      finalGeneralModules.filter(
        (i) => !i.readModule || !i.tookQuiz || i.grade < i.passingGrade,
      ),
    );
    setIsLoading(false);
  };

  const fetchModules = async (f) => {
    const moduleNameSnapshot = await db
      .collection('modules')
      .doc(f.moduleId)
      .get();
    let moduleData = moduleNameSnapshot.data();

    // if there is no topicId no need to proceed further
    if (!moduleData?.topicId) {
      return {};
    }

    let topicsSnapshot = await db
      .collection(`topics/${moduleData.topicId}/topics`)
      .get();
    moduleData.topicSize = topicsSnapshot.size;
    let quizSnapshot = await db.collection('quiz').doc(moduleData.quizId).get();
    let passingGrade = quizSnapshot.passRate ?? SHARED_CONFIG.passingGrade;

    return { ...f, passingGrade, moduleData };
  };

  const fetchTopic = async (topId) => {
    const topicIdRef = await db.collection('topics').doc(topId);

    const topicIdCollection = await db
      .collection('topics')
      .doc(topId)
      .collection('topics')
      .orderBy('order');

    let data = [];
    const snapshot = await topicIdRef.get();
    const snapshotCollection = await topicIdCollection.get();
    snapshotCollection.forEach((doc) => {
      data.push(doc.data());
    });

    setModuleId(snapshot.data());
    return data;
  };

  const handleOnTrainingClick = async (props) => {
    const { topicId } = props;
    let topicss = await fetchTopic(topicId);
    setTopics(topicss);
    handleOnNavigate('gotoTopic');
    setShowDrawer(true);
  };

  const handleOnQuizClick = async (props) => {
    const { topicId } = props;
    let topicss = await fetchTopic(topicId);
    setTopics(topicss);
    handleOnNavigate('gotoQuiz');
    setShowDrawer(true);
  };

  const handleOnNavigate = (text) => {
    if (text === 'gotoTopic') {
      setDrawerColor('white');
      setShowQuiz(false);
      setShowTopic(true);
    } else if (text === 'gotoQuiz') {
      setDrawerColor('white');
      setShowTopic(false);
      setShowQuiz(true);
    }
  };

  const handleOnDrawerClosed = () => {
    fetchAssignedModules();
    setShowDrawer(false);
  };

  async function disableNewTrainingNotification() {
    await db.collection('users').doc(user.id).update({
      // Dot notation allows you to update a single nested field
      'notifications.hasNewTrainingInfo': false,
    });
    await refreshUser();
  }

  const renderDrawerContent = () => {
    if (showTopic === true && topics.length > 0) {
      return (
        <div>
          <Topics
            data={topics}
            moduleData={moduleId}
            showTopic={setShowTopic}
            showQuiz={setShowQuiz}
            dataDrawer={handleOnDrawerClosed}
          />
        </div>
      );
    } else if (showQuiz === true) {
      return (
        <Quiz
          data={topics}
          moduleData={moduleId}
          dataDrawer={handleOnDrawerClosed}
        />
      );
    } else {
      return (
        <EmptyTopicQuiz
          dataDrawer={handleOnDrawerClosed}
          topTitle={activeTitle}
        />
      );
    }
  };

  // console.log('assignedModules', assignedModules);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.title}>Training Centre</div>
          <div className={styles.subtitle}>Training Modules</div>
          <div className={styles.progressContainer}>
            <Progress
              type="circle"
              className={styles.progressCircle}
              percent={
                (completedAssignedModules.length / assignedModules.length) * 100
              }
              format={() => {
                return (
                  <div style={{ margin: 5 }}>
                    <div
                      className="topText"
                      style={{ color: '#52C41A' }}
                    >{`${completedAssignedModules.length} of ${assignedModules.length}`}</div>
                    <div style={{ color: '#595959' }} className="bottomText">
                      PASSED
                    </div>
                  </div>
                );
              }}
              strokeColor="#52C41A"
              width={90}
              style={{ marginRight: 10 }}
            />
            <div className={styles.description}>
              Pass all modules and continue perfecting your quiz score for
              opportunities in securing a part at high profile events.
            </div>
          </div>
          <div
            className={styles.trainingInfo}
            onClick={async () => {
              setShowTrainingInformation(true);
              await disableNewTrainingNotification();
            }}
          >
            <div className={styles.trainingInfoTitle}>
              <h3>Training information</h3>
              {/* temporary icon */}
              <div className={styles.trainingInfoIcons}>
                {user?.notifications?.hasNewTrainingInfo && (
                  <ExclamationCircleFilled
                    className={styles.trainingInfoNotification}
                  />
                )}
                <RightOutlined style={{ color: '#1890ff' }} />
              </div>
            </div>

            <p>
              Upload training documents you have, or view training documents
              uploaded by event managers.
            </p>
          </div>
        </div>
        <Divider />
        <TrainingContent
          incompletedAssignedModules={incompletedAssignedModules}
          completedAssignedModules={completedAssignedModules}
          handleOnTrainingClick={handleOnTrainingClick}
          setActiveTitle={setActiveTitle}
          handleOnQuizClick={handleOnQuizClick}
        />

        <Drawer
          placement="bottom"
          closable={false}
          visible={showDrawer}
          height="100%"
          destroyOnClose
          bodyStyle={{ backgroundColor: drawerColor }}
        >
          {renderDrawerContent()}
        </Drawer>
      </div>
      <Drawer
        placement="bottom"
        closable={false}
        visible={showTrainingInformation}
        height="100%"
        destroyOnClose
        bodyStyle={{ backgroundColor: drawerColor }}
        className={styles.drawerContainer}
      >
        <div>
          <div className={styles.drawerHeader}>
            <LeftOutlined
              className={styles.backIcon}
              onClick={() => setShowTrainingInformation(false)}
            />
            <div>
              <div className={styles.title}>Training Information</div>
            </div>
          </div>
          <div className={styles.content}>
            <TrainingInformation />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default TrainingCentre;
