import { Divider } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FirebaseContext, UserContext } from '../../../../contexts';
import styles from '../styles/TrainingInformation.module.scss';
import { UploadButton } from './UploadButton';
import { WorkerDocument } from './WorkerDocument';
import { AdminDocument } from './AdminDocument';
import { TrainingLogPreview } from './TrainingLogPreview';
import { uploadFileFirebase } from '../services/trainingInformationFirebase';

export function TrainingInformation() {
  const { user } = useContext(UserContext);
  const firebase = useContext(FirebaseContext);

  const [workerFiles, setWorkerFiles] = useState([]);
  const [adminFiles, setAdminFiles] = useState([]);
  const [trainingLogs, setTrainingLogs] = useState([]);
  const [uploadError, setUploadError] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  const db = firebase.firestore();

  // get realtime updates and set the files
  useEffect(() => {
    const unsubscribe = db
      .collection('userCertificates')
      .where('userId', '==', user.id)
      .onSnapshot((snapshot) => {
        // get data
        const newFiles = snapshot.docs.map((doc) => doc.data());
        // Sort files by date
        const sortedFiles = newFiles.sort((a, b) => {
          return b.createdAt - a.createdAt;
        });
        // organize the data
        const newWorkerFiles = [];
        const newAdminFiles = [];
        sortedFiles.forEach((file) => {
          if (!file.uploadByAdmin) {
            newWorkerFiles.push(file);
          }
          if (file.uploadByAdmin) {
            newAdminFiles.push(file);
          }
        });
        setWorkerFiles(newWorkerFiles);
        setAdminFiles(newAdminFiles);
        // if onSnapshot runs it means that the upload is finished
        setUploadLoading(false);
      });

    return unsubscribe;
  }, []);

  // get realtime updates and set the trainingLogs
  useEffect(() => {
    const unsubscribe = db
      .collection('trainingLogs')
      .where('userId', '==', user.id)
      .onSnapshot((snapshot) => {
        // get data
        const trainingLogsData = snapshot.docs.map((doc) => doc.data());
        // sort by completionDate
        const sortedTrainingLogs = trainingLogsData.sort(
          (a, b) => new Date(a.completionDate) - new Date(b.completionDate),
        );

        setTrainingLogs(sortedTrainingLogs);
      });
    return unsubscribe;
  }, []);

  async function handleUpload(event) {
    setUploadError([]);
    // to be able to loop through files
    const newFiles = Array.from(event.target.files);
    // if user clicked cancel on upload
    if (!newFiles) {
      return;
    }

    setUploadLoading(true);
    // upload all files - if any has wrong fileType display error
    await Promise.all(
      newFiles.map(async (file) => {
        const isSuccessful = await uploadFileFirebase(firebase, file, user);
        if (!isSuccessful) {
          setUploadError((prevUploadError) => [...prevUploadError, file.name]);
        }
      }),
    );
    setUploadLoading(false);
  }

  return (
    <div className={styles.TrainingInformation}>
      <div className={styles.sectionTitle}>UPLOAD</div>
      <UploadButton handleUpload={handleUpload} uploadLoading={uploadLoading} />
      {uploadError.map((wrongFileName) => (
        <div className={styles.uploadError} key={wrongFileName}>
          <b>{wrongFileName}</b> has an invalid file type.
        </div>
      ))}
      {Boolean(uploadError.length) && (
        <div className={styles.uploadError}>
          Please upload a PDF or an image file.
        </div>
      )}
      {workerFiles.map((file) => (
        <WorkerDocument file={file} key={file.id} handleUpload={handleUpload} />
      ))}
      <Divider />
      <div className={styles.sectionTitle}>YOUR TRAINING INFORMATION</div>
      {adminFiles.map((file) => (
        <AdminDocument file={file} key={file.id} />
      ))}
      {trainingLogs.map((trainingLog) => (
        <TrainingLogPreview trainingLog={trainingLog} key={trainingLog.id} />
      ))}
    </div>
  );
}
