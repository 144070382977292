import { useEffect, useState, useContext } from 'react';
import { Avatar } from 'antd';
import Head from 'next/head';
// import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import {
  BottomNavigation,
  Events,
  TrainingCentre,
  Notifications,
} from '../../pwa/modules/home';
import { UserContext } from '../../contexts';
import styles from '../../pwa/styles/home/Home.module.scss';

const Home = () => {
  // const { t, i18n } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('events');
  const router = useRouter();
  const { user } = useContext(UserContext);

  useEffect(() => {
    // i18n.changeLanguage('admin_en');
  }, []);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const renderContent = () => {
    if (selectedTab === 'events') {
      return <Events handleTabChange={handleTabChange} />;
    }

    if (selectedTab === 'training') {
      return <TrainingCentre />;
    }

    if (selectedTab === 'announcements') {
      return <Notifications />;
    }

    return null;
  };

  return (
    <div>
      <div
        className={styles.avataru}
        onClick={() => router.push('/app/profile')}
      >
        {user && (
          <div>
            {user.profilePhoto ? (
              <Avatar size={35} src={user.profilePhoto} />
            ) : (
              <Avatar style={{ color: '#000', backgroundColor: '#FCEA25' }}>
                <span style={{ textTransform: 'capitalize' }}>
                  {user.firstname[0]}
                </span>
              </Avatar>
            )}
          </div>
        )}
      </div>
      <div className={styles.container}>{renderContent()}</div>
      <BottomNavigation
        selected={selectedTab}
        onClick={(tab) => setSelectedTab(tab)}
      />
    </div>
  );
};

export default Home;
