async function getGlobalNotificationsFirestore(firebase, user) {
  const companies = user.memberOfCompanyId
  const db = firebase.firestore();
  const notificationsSnapshot = await db
    .collection('notifications')
    .where('type', '==', 'global')
    .get();
  const globalNotifications = notificationsSnapshot.docs.map((doc) => doc.data());  
  return  globalNotifications.filter(item => companies.includes(item.companyId));
}

async function getNotificationsByEventId(firebase, eventId) {
  const db = firebase.firestore();
  const notifications = await db
    .collection('notifications')
    .where('eventId', '==', eventId)
    .get();
  return notifications.docs.map((doc) => doc.data());
}

async function getMultipleNotificationsByEventsIds(firebase, arrayOfEventsIds) {
  const notificationsPromises = arrayOfEventsIds.map((eventId) =>
    getNotificationsByEventId(firebase, eventId),
  );
  const notifications = await Promise.all(notificationsPromises);
  return notifications.flat(1);
}

export {
  getGlobalNotificationsFirestore,
  getNotificationsByEventId,
  getMultipleNotificationsByEventsIds,
};