import { populateArrayOfIds } from 'utils';
import { ROLES } from '../admin/constants/roles';
import { haveCommonElement } from './general';
import { getAdminDepartmentsIds } from './objectGetters/adminGetters';
import { getWorkerDepartmentsIds } from './objectGetters/workerGetters';

/**
 *  Filters an array of workers based on the role and access privileges of the admin user.
 *
 * If adminUser is:
 *
 * `companyAdmin` -> sees all workers
 *
 * `eventManager` -> sees only workers that belong to his departments or don't have department
 *
 * `agentRepresentative` -> sees only workers that belong to his departments AND agency
 */
export function filterWorkersByAdminRole(adminUser, arrayOfWorkers, companyId) {
  let filteredWorkersArray = arrayOfWorkers;
  // Show only the workers that have one of the agencies of the agentRepresentative
  if (adminUser?.role === ROLES.agentRepresentative) {
    filteredWorkersArray = filterWorkersWithTheSameAgency(
      adminUser,
      filteredWorkersArray,
      companyId,
    );
  }
  if (
    adminUser?.role === ROLES.eventManager ||
    adminUser?.role === ROLES.agentRepresentative
  ) {
    // Show only the workers that have one of the departments of the manager
    filteredWorkersArray = filterWorkersWithSameDepartment(
      adminUser,
      filteredWorkersArray,
      companyId,
    );
  }
  return filteredWorkersArray;
}

function filterWorkersWithTheSameAgency(adminUser, arrayOfWorkers, companyId) {
  const adminAgencies = adminUser?.memberOfAgencyId;
  if (!adminAgencies?.length) {
    // if admin doesn't have any agencies we don't show him any workers
    return [];
  }

  return arrayOfWorkers.filter((worker) => {
    const workerAgencies = worker?.agency?.companyId[companyId];

    if (!workerAgencies?.length) {
      // ! difference from departments filtering
      return false;
    }

    const areSomeAgenciesTheSame = haveCommonElement(
      adminAgencies,
      workerAgencies,
    );
    return areSomeAgenciesTheSame;
  });
}

function filterWorkersWithSameDepartment(adminUser, arrayOfWorkers, companyId) {
  const managerDepartments = getAdminDepartmentsIds(adminUser, companyId);
  if (!managerDepartments.length) {
    // if admin doesn't have any departments we don't show him any workers
    return [];
  }

  const filteredArray = arrayOfWorkers.filter((worker) => {
    const workerDepartments = getWorkerDepartmentsIds(worker, companyId);

    // if worker doesn't have any department show him so the manager can assign him one.
    if (!workerDepartments.length) {
      return true;
    }
    return haveCommonElement(workerDepartments, managerDepartments);
  });
  return filteredArray;
}

/**
 * Retrieves the departments visible to a user based on their role and company context.
 * companyAdmins view all departments while eventManagers and agentRepresentatives
 * can only see the departments they belong to.
 *
 * @param {object} user - The user for whom to retrieve visible departments.
 * @param {string} companyId - The ID of the company to which the user belongs.
 * @param {Array<Object>} companyDepartments - An array of company department objects.
 * @returns {Array<Object>} An array of department objects visible to the user.
 *
 */
export function getDepartmentsVisibleForUser(
  user,
  companyId,
  companyDepartments,
) {
  const userRoles = [ROLES.eventManager, ROLES.agentRepresentative];

  if (userRoles.includes(user?.role)) {
    const adminDepartmentsIds = getAdminDepartmentsIds(user, companyId);
    return populateArrayOfIds(adminDepartmentsIds, companyDepartments);
  }

  return companyDepartments;
}

/**
 * Filters notifications to only show ones that should be visible to worker,
 * based on departments he belongs to.
 *
 * @param {Object} worker - The worker for whom notifications are being filtered.
 * @param {Array} notifications - The list of notifications to be filtered.
 * @returns {Array} - An array of notifications that are applicable to the worker.
 */
export function filterNotificationsPerDepartments(worker, notifications) {
  return notifications.filter((notification) => {
    const isForAllDepartments =
      notification?.sendToAllDepartments === undefined ||
      notification?.sendToAllDepartments === true;

    if (isForAllDepartments) {
      return true;
    }

    const workerDepartments = getWorkerDepartmentsIds(
      worker,
      notification.companyId,
    );
    // if worker doesn't have any department in this company don't show him notification
    if (!workerDepartments.length) {
      return false;
    }

    const notificationDepartments = notification.departmentsIds;

    return haveCommonElement(notificationDepartments, workerDepartments);
  });
}

/**
 * Filters sections to only show ones that should be visible to Admin,
 * based on departments he belongs to. CompanyAdmins see all the sections.
 */
export function filterSectionsPerDepartments(admin, companyId, sections) {
  const isAdminEventManagerOrAgentRep =
    admin?.role === ROLES.eventManager ||
    admin?.role === ROLES.agentRepresentative;

  if (!isAdminEventManagerOrAgentRep) {
    return sections;
  }

  const adminDepartmentsIds = getAdminDepartmentsIds(admin, companyId);

  if (!adminDepartmentsIds?.length) {
    return sections;
  }

  // Filter sections to include only those with common department IDs with the admin.
  return sections.filter((section) => {
    if (!section.departmentsIds?.length) {
      // If the section has no department IDs, include it.
      return true;
    } else {
      // Check if there are common elements between admin and section department IDs.
      return haveCommonElement(adminDepartmentsIds, section.departmentsIds);
    }
  });
}
