import styles from '../styles/TrainingInformation.module.scss';

export function AdminDocument({ file }) {
  return (
    <a
      className={styles.AdminDocument}
      href={file.fileURL}
      target="_blank"
      rel="noopener noreferrer"
    >
      {file.fileName}
    </a>
  );
}
