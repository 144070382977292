export const Logo = () => {
  // return (
  //   <svg width="172" height="133" viewBox="0 0 172 133" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <rect x="44" y="2.82788" width="91" height="91.344" rx="19.5" fill="#0A0B09"/>
  //   <rect x="44" y="2.82788" width="91" height="91.344" rx="19.5" stroke="#EAEAEA"/>
  //   <rect x="0.726631" y="33.2534" width="53.2012" height="121.046" transform="rotate(-37.483 0.726631 33.2534)" fill="url(#paint0_linear_3160_7907)" fill-opacity="0.16"/>
  //   <rect x="133.874" y="9" width="53.2012" height="121.046" transform="rotate(44.5207 133.874 9)" fill="url(#paint1_linear_3160_7907)" fill-opacity="0.16"/>
  //   <path fill-rule="evenodd" clip-rule="evenodd" d="M89.7712 40.8489C88.4714 40.8489 87.1925 41.0086 85.9449 41.2992L89.5425 33.0618L93.0937 41.1926C92.005 40.9736 90.8961 40.8489 89.7712 40.8489ZM105.392 54.0142L92.3914 24.2435C92.0254 23.4057 91.3625 22.7706 90.5726 22.5016C89.0257 21.9715 87.3713 22.6904 86.7266 24.1688L73.6079 54.2078C73.3948 54.6961 73.5028 55.2546 73.884 55.6302C74.1307 55.8743 74.4442 56.0018 74.7673 56.0018C74.9414 56.0018 75.1185 55.9641 75.2892 55.8887C76.5202 55.34 77.7935 54.8587 79.0722 54.4578C79.9012 54.1985 80.5688 53.6107 80.9031 52.8435L82.6602 48.8202C84.7689 47.2024 87.2249 46.3455 89.7713 46.3455C92.0529 46.3455 94.2657 47.0402 96.2189 48.3485L98.1264 52.7166C98.466 53.496 99.1457 54.0878 99.9907 54.3421C101.259 54.7245 102.516 55.1824 103.727 55.7025C104.212 55.9111 104.747 55.8063 105.122 55.4314C105.498 55.0549 105.604 54.499 105.392 54.0142ZM103.689 60.5587C99.5818 57.7918 94.7816 56.3301 89.8075 56.3301C84.7143 56.3301 79.8191 57.8574 75.6512 60.7458C75.0469 61.1652 74.6422 61.7942 74.5119 62.5163C74.3814 63.2393 74.5413 63.9688 74.9615 64.5718C75.4766 65.3099 76.3235 65.7512 77.2273 65.7512C77.791 65.7512 78.3339 65.5808 78.7986 65.2594C82.0375 63.0136 85.8441 61.8267 89.8075 61.8267C93.677 61.8267 97.4101 62.9633 100.603 65.1126C101.863 65.9615 103.581 65.6295 104.431 64.374C104.843 63.7657 104.993 63.0338 104.852 62.3133C104.712 61.5926 104.299 60.9697 103.689 60.5587ZM89.7638 66.6618C93.1488 66.6618 96.4152 67.6564 99.2099 69.5393C100.469 70.3879 100.802 72.0998 99.951 73.3552C99.0992 74.61 97.382 74.9421 96.1234 74.0931C94.2437 72.8277 92.0447 72.1584 89.7638 72.1584C87.4284 72.1584 85.1858 72.8579 83.2778 74.1804C82.8129 74.5018 82.2699 74.6721 81.7065 74.6721C80.8024 74.6721 79.9558 74.2309 79.4405 73.4928C78.5731 72.2492 78.8828 70.5325 80.1302 69.6668C82.9662 67.7011 86.2973 66.6618 89.7638 66.6618Z" fill="#FCEA25"/>
  //   <defs>
  //   <linearGradient id="paint0_linear_3160_7907" x1="27.3272" y1="33.2534" x2="27.3272" y2="154.3" gradientUnits="userSpaceOnUse">
  //   <stop stop-color="white" stop-opacity="0.2"/>
  //   <stop offset="1" stop-color="white"/>
  //   </linearGradient>
  //   <linearGradient id="paint1_linear_3160_7907" x1="160.474" y1="9" x2="160.474" y2="130.046" gradientUnits="userSpaceOnUse">
  //   <stop stop-color="white" stop-opacity="0.21"/>
  //   <stop offset="1" stop-color="white" stop-opacity="0.86"/>
  //   </linearGradient>
  //   </defs>
  // </svg>
  // )

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="43" viewBox="0 0 27 43" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.717 15.2156C12.6771 15.2156 11.6541 15.3433 10.6561 15.5758L13.534 8.98626L16.3748 15.4906C15.5039 15.3154 14.6168 15.2156 13.717 15.2156ZM26.213 25.7473L15.813 1.93204C15.5202 1.26187 14.9899 0.753847 14.358 0.53865C13.1206 0.114584 11.7971 0.689623 11.2814 1.87228L0.787025 25.9022C0.616547 26.2927 0.703 26.7395 1.00792 27.04C1.20528 27.2353 1.45605 27.3373 1.71448 27.3373C1.85377 27.3373 1.99549 27.3071 2.13199 27.2468C3.11676 26.8078 4.13533 26.4229 5.1582 26.1021C5.82144 25.8947 6.35546 25.4245 6.62285 24.8107L8.0285 21.5923C9.71535 20.2981 11.68 19.6127 13.717 19.6127C15.5422 19.6127 17.3123 20.1684 18.8748 21.2149L20.4007 24.7093C20.6724 25.3327 21.2161 25.8061 21.8921 26.0096C22.9067 26.3155 23.9126 26.6818 24.8813 27.0979C25.269 27.2647 25.6969 27.1809 25.997 26.881C26.2978 26.5798 26.3826 26.1351 26.213 25.7473ZM24.8505 30.9825C21.565 28.7691 17.725 27.5999 13.746 27.5999C9.67168 27.5999 5.75573 28.8216 2.42162 31.1322C1.93819 31.4676 1.61442 31.9708 1.51023 32.5485C1.40585 33.1269 1.53375 33.7105 1.86985 34.1928C2.28195 34.7833 2.95938 35.1362 3.68237 35.1362C4.1333 35.1362 4.56762 35 4.93938 34.7429C7.53034 32.9463 10.5754 31.9969 13.746 31.9969C16.8414 31.9969 19.8277 32.9061 22.3823 34.6254C23.39 35.3045 24.7641 35.0389 25.4438 34.0346C25.7735 33.5479 25.8934 32.9625 25.7812 32.3861C25.6691 31.8096 25.3388 31.3113 24.8505 30.9825ZM13.711 35.8648C16.4189 35.8648 19.0319 36.6604 21.2675 38.1666C22.2748 38.8456 22.5409 40.2149 21.8603 41.2193C21.179 42.223 19.8053 42.4887 18.7985 41.8096C17.2948 40.7972 15.5357 40.2618 13.711 40.2618C11.8429 40.2618 10.0488 40.8214 8.52255 41.8794C8.1506 42.1364 7.71628 42.2727 7.26554 42.2727C6.54236 42.2727 5.86512 41.9198 5.45283 41.3293C4.75897 40.3344 5.00675 38.9612 6.0046 38.2687C8.27328 36.6962 10.938 35.8648 13.711 35.8648Z" fill="#FCEA25"/>
    </svg>
  )
}