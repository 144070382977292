import styles from '../styles/TrainingInformation.module.scss';

// UTILITY FUNCTIONS

function getStatusStyles(status) {
  switch (status.toLowerCase()) {
    case 'pending approval':
      return { backgroundColor: '#EAEAEA', color: '#595959' };
    case 'approved':
      return { backgroundColor: '#FCEA25', color: '#0A0B09' };
    case 'rejected':
      return { backgroundColor: '#FB4953', color: '#FFFFFF' };
    default:
      return { backgroundColor: '#EAEAEA', color: ' #595959' };
  }
}

// MAIN COMPONENT

export function DocumentField({
  renaming,
  file,
  newFileName,
  setNewFileName,
  newFileType,
}) {
  if (renaming) {
    return (
      <div className={styles.document}>
        <input
          type="text"
          value={newFileName}
          onChange={(e) => setNewFileName(e.target.value)}
          className={styles.documentInput}
        />
        <div
          className={styles.documentStatus}
          style={getStatusStyles(file.fileStatus)}
        >
          {file.fileStatus}
        </div>
      </div>
    );
  } else {
    return (
      <a
        className={styles.document}
        href={file.fileURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {`${newFileName}.${newFileType}`}
        <div
          className={styles.documentStatus}
          style={getStatusStyles(file.fileStatus)}
        >
          {file.fileStatus}
        </div>
      </a>
    );
  }
}
