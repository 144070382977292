import moment from 'moment';

function checkDateLaterThanToday(date, time) {
  if (!date || !time) return false;

  let momentObj = moment(`${date} ${time}`, 'X HH:mm');
  return moment().isAfter(momentObj);
}

export function isEventFeedbackAvailable(eventData) {
  console.log('eventData', eventData);
  const islater = checkDateLaterThanToday(
    eventData?.postEventDate?.seconds,
    eventData?.postEventTime,
  );
  const showFeedbackQuestion = eventData?.hasFeedbackQuestion;

  return islater && showFeedbackQuestion;
}
