import { useState } from 'react';
import { TrainingBox } from '../../components';
import { TabSelect } from '../../components/TabSelect';
import styles from '../../styles/components/TrainingCentre.module.scss';

function TrainingContent({
  incompletedAssignedModules,
  completedAssignedModules,
  handleOnTrainingClick,
  setActiveTitle,
  handleOnQuizClick,
}) {
  const [isInProgressSelected, setIsInProgressSelected] = useState(true);

  function selectInProgress() {
    setIsInProgressSelected(true);
  }

  function selectCompleted() {
    setIsInProgressSelected(false);
  }

  return (
    <>
      <div className={styles.middleContainer}>
        <div className={styles.title}>Trainings</div>
      </div>
      <div className={styles.tabSelectTraining}>
        <TabSelect
          text="In Progress"
          onClick={selectInProgress}
          isSelected={isInProgressSelected}
        />
        <TabSelect
          text="Completed"
          onClick={selectCompleted}
          isSelected={!isInProgressSelected}
        />
      </div>
      {isInProgressSelected && (
        <>
          {incompletedAssignedModules.length === 0 && (
            <div>
              <div className={styles.emptyModuleTitle}>Welcome to Aposto</div>{' '}
              <div className={styles.emptyModuleDescription}>
                You do not currently have any training modules assigned to you.
                Once training modules have been assigned to you, you will see
                them here.
              </div>
            </div>
          )}
          {incompletedAssignedModules?.map((item, index) => {
            return (
              <TrainingBox
                name={item?.moduleData?.title}
                topicId={item?.moduleData?.topicId}
                quizId={item?.moduleData?.quizId}
                tookQuiz={item.tookQuiz}
                readProgress={item.readProgress}
                onClick={(topicId) => {
                  handleOnTrainingClick(topicId);
                  setActiveTitle(item?.moduleData?.title);
                }}
                onClickBot={(topicId) => handleOnQuizClick(topicId)}
                topicSize={item?.moduleData?.topicSize}
                grade={item.grade}
              />
            );
          })}
        </>
      )}
      {!isInProgressSelected && (
        <>
          {completedAssignedModules.length === 0 && (
            <div className={styles.emptyModuleDescription}>
              You have no completed module assigned to you.
            </div>
          )}
          {completedAssignedModules?.map((item, index) => {
            return (
              <TrainingBox
                key={`training-box-${item?.moduleData?.moduleId}-${index}`}
                name={item?.moduleData?.title}
                topicId={item?.moduleData?.topicId}
                quizId={item?.moduleData?.quizId}
                tookQuiz={item.tookQuiz}
                readProgress={item.readProgress}
                onClick={(topicId) => {
                  handleOnTrainingClick(topicId);
                  setActiveTitle(item?.moduleData?.title);
                }}
                onClickBot={(topicId) => handleOnQuizClick(topicId)}
                topicSize={item?.moduleData?.topicSize}
                grade={item.grade}
              />
            );
          })}
        </>
      )}
    </>
  );
}

export { TrainingContent };
