import { Drawer } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { filterNotificationsPerDepartments } from 'utils/departmentUtils';
import { FirebaseContext, UserContext } from '../../../contexts';
import { getEventsWithWorkerFirestore } from '../../../firebase/events';
import {
  getGlobalNotificationsFirestore,
  getMultipleNotificationsByEventsIds,
} from '../../../firebase/notifications';
import { filterNotificationsPerRoles } from '../../../utils/rolesUtils';
import { Feedback, Loading } from '../../components';
import styles from '../../styles/home/Notifications.module.scss';
import {
  EventCard,
  GlobalCard,
  NewTag,
  SelectedNotifications,
} from './NotificationsMolecules';
import { isEventFeedbackAvailable } from './NotificationsUtils';

// don't delete -> uncomment for visibility if needed
// this is a schema of objects inside of eventData, globalData and selectedData states

// const notiDataSchema = {
//   eventData: {
//     eventId: 'id of an event',
//     eventImage: 'url to event image',
//     eventName: 'event name',
//     eventDate: 'firebase timestamp',
//     eventLocation: {
//       place: 'ex. Veltnis Arena',
//       city: 'ex. Rotterdam',
//       country: 'ex. Netherlands',
//     },
//   },
//   latestDate: 'firebase timestamp of the last message',
//   content: [
//     {
//       id: 'id of notification',
//       type: 'event or global',
//       title: 'notification title',
//       content: 'notification content',
//       dateCreated: 'firebase timestamp',
//     },
//   ],
// };

function findNotificationByEventId(notifications, eventId) {
  return notifications.find((notification) => notification.eventId === eventId);
}

function sortEventsByLastNotificationDate(events, notificationsSortedByDate) {
  const workerEventsByDate = events.toSorted((event1, event2) => {
    const event1NewestNotification = findNotificationByEventId(
      notificationsSortedByDate,
      event1.eventId,
    );
    const event2NewestNotification = findNotificationByEventId(
      notificationsSortedByDate,
      event2.eventId,
    );
    // if there is no notification, take time of the feedback
    const event1lastDate = event1NewestNotification
      ? event1NewestNotification.dateCreated
      : event1.postEventDate;

    const event2lastDate = event2NewestNotification
      ? event2NewestNotification.dateCreated
      : event2.postEventDate;
    return event2lastDate - event1lastDate;
  });
  return workerEventsByDate;
}

const Notifications = () => {
  const firebase = useContext(FirebaseContext);
  const { user } = useContext(UserContext);
  const [notificationDetailView, setNotificationDetailView] = useState(false);
  const [notificationFeedbackView, setNotificationFeedbackView] = useState(
    false,
  );

  // notifications of currently selected item (event or global)
  const [selectedData, setSelectedData] = useState();
  // object containing global notifications
  const [globalData, setGlobalData] = useState({});
  // array containing events with corresponding notifications
  const [eventData, setEventData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      // get data of events that worker is invited to
      // get data of global notifications
      const [workerEvents, globalNotifications] = await Promise.all([
        getEventsWithWorkerFirestore(firebase, user.id),
        getGlobalNotificationsFirestore(firebase, user),
      ]);

      // use the ids to fetch notifications that are assigned to the events
      const workerEventsIds = workerEvents.map((event) => event.eventId);
      const eventsNotifications = await getMultipleNotificationsByEventsIds(
        firebase,
        workerEventsIds,
      );

      // filter out notifications that are issued to a diffrent departments than ones of the user
      const globalNotificationsByDepartments = filterNotificationsPerDepartments(
        user,
        globalNotifications,
      );
      const eventsNotificationsByDepartments = filterNotificationsPerDepartments(
        user,
        eventsNotifications,
      );

      // filter out notifications further, now by roles
      const filteredGlobalNotifications = filterNotificationsPerRoles(
        user,
        globalNotificationsByDepartments,
      );
      const filteredEventsNotifications = filterNotificationsPerRoles(
        user,
        eventsNotificationsByDepartments,
      );

      // filter out all events that don't have notifications or feedback
      const workerEventsWithNotifications = workerEvents.filter(
        (workerEvent) => {
          const hasNotifications = filteredEventsNotifications.some(
            (notif) => notif.eventId === workerEvent.eventId,
          );
          const hasFeedback = isEventFeedbackAvailable(workerEvent);
          return hasNotifications || hasFeedback;
        },
      );

      const eventsNotificationsByDate = filteredEventsNotifications.toSorted(
        (x, y) => {
          return y.dateCreated - x.dateCreated;
        },
      );

      const workerEventsByDate = sortEventsByLastNotificationDate(
        workerEventsWithNotifications,
        eventsNotificationsByDate,
      );

      // create an object with combined notifications and events data:
      const eventDataWithNotifications = workerEventsByDate.map((event) => {
        const currentEventNotifications = eventsNotificationsByDate.filter(
          (noti) => noti.eventId === event.eventId,
        );

        return {
          eventData: event,
          // if there is no notification take the date of the feedback
          latestDate:
            currentEventNotifications?.[0]?.dateCreated ?? event.postEventDate,
          content: currentEventNotifications,
        };
      });
      setEventData(eventDataWithNotifications);

      // create an object with global notifications sorted by date:
      const globalNotificationsByDate = filteredGlobalNotifications.toSorted(
        (x, y) => {
          return y.dateCreated - x.dateCreated;
        },
      );
      setGlobalData({
        content: globalNotificationsByDate,
        latestDate: globalNotificationsByDate[0]?.dateCreated,
        eventData: { eventName: 'General Announcements' },
      });
      setIsLoading(false);
    }

    fetchData();
  }, []);

  function showNotifications(dataToShow) {
    setSelectedData(dataToShow);
    setNotificationDetailView(true);
  }

  function hideNotifications() {
    setNotificationDetailView(false);
  }

  function showEventFeedback() {
    setNotificationFeedbackView(true);
  }

  function hideEventFeedback() {
    setNotificationFeedbackView(false);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.subtitle}>
          Announcements
          <NewTag />
        </div>
        {globalData?.content?.length && (
          <GlobalCard
            globalData={globalData}
            showNotifications={showNotifications}
          />
        )}
        {eventData.map((item) => (
          <EventCard
            key={item.eventData.eventId}
            item={item}
            showNotifications={showNotifications}
          />
        ))}
      </div>
      <Drawer
        placement="bottom"
        closable={false}
        onClose={hideNotifications}
        visible={notificationDetailView}
        height="100%"
        destroyOnClose
      >
        <SelectedNotifications
          selectedData={selectedData}
          hideNotifications={hideNotifications}
          showEventFeedback={showEventFeedback}
        />
      </Drawer>
      <Drawer
        placement="right"
        closable={false}
        onClose={hideNotifications}
        visible={notificationFeedbackView}
        width="100%"
        destroyOnClose
      >
        <Feedback
          notiData={selectedData}
          onPressBack={hideEventFeedback}
          eventId={selectedData?.eventData?.eventId}
        />
      </Drawer>
    </div>
  );
};

export default Notifications;
