import { useState, useEffect } from 'react';
import styles from '../../styles/BottomNavigation.module.scss';
import { Icon } from '../../components';
import { useNotificationStatus } from './hooks/useNotificationStatus';

const BottomNavigation = (props) => {
  const { onClick, selected } = props;
  const [
    hasNewNotifications,
    handleHasSeenNotification,
  ] = useNotificationStatus();

  const handleAnnouncementsClick = () => {
    handleHasSeenNotification();
    onClick('announcements');
  };

  return (
    <div className={styles.container}>
      <div
        className={
          selected === 'events'
            ? `${styles.button} ${styles.buttonSelected}`
            : styles.button
        }
        onClick={() => onClick('events')}
      >
        <Icon name="icon-football" size={26} />
      </div>
      <div
        className={
          selected === 'training'
            ? `${styles.button} ${styles.buttonSelected}`
            : styles.button
        }
        onClick={() => onClick('training')}
      >
        <Icon name="icon-healthcare-and-medical" size={26} />
      </div>
      <div
        className={
          selected === 'announcements'
            ? `${styles.button} ${styles.buttonSelected}`
            : styles.button
        }
        onClick={() => handleAnnouncementsClick()}
      >
        <div style={{ position: 'relative', padding: '5px' }}>
          <Icon name="icon-megaphone" size={26} />
          {hasNewNotifications && <div className={styles.alertIndicator} />}
        </div>
      </div>
    </div>
  );
};

export default BottomNavigation;